@import "../scss/modules/_variables";
@import "../scss/modules/_mixins";

.day-title {
  background-color: $brand-secondary;
  color: #fff;
  padding: 0.75rem $layout-padding-x;
  margin-left: -$layout-padding-x;
  margin-right: -$layout-padding-x;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    background-color: darken($brand-primary, 15%);
  }
}
