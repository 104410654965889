// VARIABLES
// Layout
$grid-columns: 12;
$grid-gutter: 20px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$max-width: 900px;

// Type
$font-size: 20px;
$font-size-small: 0.8em;
$font-size-large: 1.25em;
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$alt-font: $font-family; // this is for an alternative font
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 600;
$alt-font-weight-light: 300;
$alt-font-weight-normal: 400;
$alt-font-weight-bold: 700;
$font-scale: 1.5;
$line-height: $font-scale + em;
$text-margin-bottom: 1.5rem;

// Background colors
$page-background: #fff;
$footer-background: #fff;

$dark-mode-background: #222;
$dark-mode-text: #ccc;

// Text colors
$text-color: #000;
$text-light: #999;

// Design colors
$brand-primary: #008eff;
$brand-secondary: #ff00dc;
$brand-accent: #3ad8b6;
$brand-info: #4bb9de;
$brand-success: #c1e88d;
$brand-warning: #f2f846;
$brand-danger: #d94137;
$brand-light: #ccc;
$brand-dark: $text-color;
$accent: $brand-primary;
$pop: $brand-accent;

$colors: (
  primary: $brand-primary,
  secondary: $brand-secondary,
  info: $brand-info,
  success: $brand-success,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $brand-light,
  dark: $brand-dark,
);

$selection: fade-out($pop, 0.3);
$link-color: $brand-primary;
$link-hover: $brand-accent;

// Indicator colors
$color-info: $pop;
$screen-info: change-color($color-info, $lightness: 90%);
$color-success: #1d9428;
$screen-success: change-color($color-success, $lightness: 90%);
$color-warning: #d4ae2f;
$screen-warning: change-color($color-warning, $lightness: 90%);
$color-danger: #c0343b;
$screen-danger: change-color($color-danger, $lightness: 90%);

// Form colors
$form-color-button: $link-color;
$form-color-focus: $text-light;

// Line colors
$line-color: $brand-light;
$line-color-dark: #999;

// Radii
$master-radius: 0;
$form-radius: $master-radius;
$button-radius: $form-radius;
$box-radius: $master-radius;

// Padding
$padding-menu: 0.75em 1em;
$padding-button: 0.5em 1em;
$padding-btn: $padding-button;
$padding-input: 0.5rem;

$layout-padding-x: 2rem;
$layout-padding-y: 3rem;
$layout-padding-x-sm: 1rem;
$layout-padding-y-sm: 1.5rem;

// Screens
$screen: #ddd;
$screen-lt: #fff;
$screen-dk: #444;

$animation-duration: 100ms;
