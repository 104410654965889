@import "../scss/modules/_variables";
@import "../scss/modules/_mixins";

.tabs {
  margin: 0 -0.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: " ";
    order: 1;
    flex-grow: 1;
    height: 2px;
    background-color: currentColor;
  }
}
.tab-link {
  font-size: 1.25rem;
  color: $text-color;
  text-decoration: none;
  margin: 0 0.5rem;
  position: relative;
  // stuff to deal with using a button instead of link
  font-family: $font-family;
  cursor: pointer;
  background-color: transparent;
  border: 0 none;

  @media (prefers-color-scheme: dark) {
    color: $dark-mode-text;
  }
  &:nth-child(2) {
    order: 3;
  }
  &.active {
    font-weight: $font-weight-bold;
  }
  &:hover {
    color: $brand-primary;
    @media (prefers-color-scheme: dark) {
      color: #ccc;
    }
  }
}
