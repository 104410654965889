@import "../scss/modules/_variables";
@import "../scss/modules/_mixins";

.day-link {
  font-weight: $font-weight-normal;
  font-size: 0.75rem;
  color: inherit;
  margin-left: 0.5em;
  &:hover {
    color: $text-color;
  }
}
