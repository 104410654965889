/**
 * @file
 * Your Project Stylesheet
*/

// Vars
@import "scss/modules/_variables";
// Mixies
@import "scss/modules/_mixins";

// Core
@import "scss/modules/_core";
// @import "modules/_layout";

body {
  // this is for the footer
  background-color: $footer-background;
}
.page {
  background-color: $page-background;
  max-width: 900px;
}

.App-header,
main,
.footer-site {
  padding: $layout-padding-x $layout-padding-y;
  @media (max-width: breakpoint(md)) {
    padding: $layout-padding-x-sm $layout-padding-y-sm;
  }
}
.site-title {
  font-weight: $font-weight-bold;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
  @media (prefers-color-scheme: dark) {
    color: #e6e6e6;
  }
  @media (max-width: breakpoint(md)) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.site-tagline {
  display: block;
  margin-bottom: 1rem;
  color: $text-light;
}

.footer-site {
  color: $text-light;
}

// Type
@import "scss/modules/_type";

// Forms
// @import "modules/_forms";
// @import "modules/_buttons";

// Animations
// @import "modules/_animations";

@media (prefers-color-scheme: dark) {
  body,
  .page {
    background-color: $dark-mode-background;
    color: $dark-mode-text;
  }
}

html {
  scroll-behavior: smooth;
}
