/*	-----
	Reset
*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em,
img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, hr,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figure, figcaption, hgroup,
menu, footer, header, nav, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}
article, aside, canvas, figure, figure img, figcaption, hgroup,
footer, header, nav, section, audio, video {
	display: block;
}

/*	----------
	Essentials
*/

// Body
body {
	background-color:$page-background;
	color:$text-color;
}

// Basic links
a {
	color:$link-color;
	// @include transition();
	&:hover {
		color:$link-hover;
	}
	&:focus {
		@include focus();
	}
}

// Standard box sizing
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

// Responsive images
img {
	max-width:100%;
	height:auto;
}

// Selection
::selection {
	background-color:$selection;
	color:contrasting-text($selection);
}
