@import "../scss/modules/_variables";
@import "../scss/modules/_mixins";

.day-chooser {
  display: flex;
  flex-wrap: wrap;
}
.day-chooser-cell {
  width: 14.28%;
  @media (max-width: 728px) {
    width: 25%;
  }
}
.day-chooser-link {
  display: block;
  text-decoration: none;
  margin: 0.25em;
  padding: 0.75em 0.5em;
  background-color: $brand-secondary;
  color: contrasting-text($brand-secondary);
}

.back-to-today-link {
  text-align: center;
  margin-top: 1rem;
}
