@import "../scss/modules/_variables";
@import "../scss/modules/_mixins";

.passage {
  border-bottom: 2px solid #ddd;
  margin-bottom: 1em;
}

.end-line-group {
  display: block;
  padding-bottom: $text-margin-bottom;
}

// inline heading
.d,
.psalm-title {
  font-size: 0.75rem;
  line-height: 1.5;
  font-style: italic;
  color: $text-light;
}

// verse number
.v,
.verse-num {
  position: relative;
  top: -0.6em;
  font-size: 0.6em;
  margin-right: 0.2em;
}

.q1 {
  margin-bottom: 0;
}

.selah {
  display: block;
  font-size: $font-size-small;
  font-style: italic;
  color: $text-light;
}

// reference
.end-line-group + p {
  font-size: 0.8em;
  margin-top: -0.5em;
  a {
    color: inherit;
    text-decoration: none;
  }
}

#content h2 {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  @media (prefers-color-scheme: dark) {
    background-color: $dark-mode-background;
  }
}
